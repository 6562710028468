(function () {
  'use strict';

  admin.pages.dashboard = function () {
        
        $(document).ready(function(){
            // Nothing here yet
        });
  };
})();
